<template>
  <div class="main-municipality">
    <v-card>
      <div class="report">
        <h1>RELATÓRIOS</h1>
        <h2>{{ title }}</h2>
      </div>
      <v-tabs
        v-model="tab"
        background-color="#4c4c4c"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          href="#tab-1"
          v-if="
            this.$store.state.profile_id == 1 ||
            this.$store.state.profile_id == 2 ||
            this.$store.state.profile_id == 21 ||
            this.$store.state.profile_id == 41 ||
            $store.state.profile_id == 3 ||
            $store.state.profile_id == 6 ||
            $store.state.profile_id == 8
          "
        >
          FASE MUNICIPAL (Municípios)
        </v-tab>

        <v-tab
          href="#tab-2"
          v-if="
            this.$store.state.profile_id == 1 ||
            this.$store.state.profile_id == 2 ||
            this.$store.state.profile_id == 21 ||
            this.$store.state.profile_id == 41 ||
            $store.state.profile_id == 4 ||
            $store.state.profile_id == 6 ||
            $store.state.profile_id == 8
          "
        >
          FASE MUNICIPAL (Instituições)
        </v-tab>

        <v-tab
          href="#tab-3"
          v-if="
            this.$store.state.profile_id == 1 ||
            this.$store.state.profile_id == 2 ||
            this.$store.state.profile_id == 21 ||
            this.$store.state.profile_id == 41 ||
            $store.state.profile_id == 6 ||
            $store.state.profile_id == 8
          "
        >
          FASE MACRORREGIONAL
        </v-tab>
        <v-tab
          href="#tab-4"
          v-if="
            this.$store.state.profile_id == 1 ||
            this.$store.state.profile_id == 2 ||
            this.$store.state.profile_id == 21 ||
            this.$store.state.profile_id == 41 ||
            $store.state.profile_id == 8
          "
        >
          FASE ESTADUAL
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" value="tab-1">
          <reportGraphCounty
            v-if="conference_id"
            :conference_id="conference_id"
            :phase_id="1"
            :type_request="'COUNTY'"
          >
          </reportGraphCounty>

          <div class="main-report">
            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Delegados"
                type_select="delegate"
                report_type="county"
              />
              <span>Relação de Delegados</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Propostas"
                type_select="proposal"
                report_type="county"
              />
              <span>Relação de Propostas</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Diretrizes"
                type_select="guideline"
                report_type="county"
              />
              <span>Relação de Diretrizes</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Facilitadores"
                type_select="facilitator"
                report_type="county"
              />

              <span>Relação de Facilitadores</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Comissões"
                type_select="comission"
                report_type="county"
              />

              <span>Relação de Comissões</span>
            </div>
            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Observações"
                type_select="observation"
                report_type="county"
              />

              <span>Relação de Observações</span>
            </div>

            <div class="btn-emitir" v-if="initial_macro_phase_date == null">
              <btnEmit
                :resource="resource"
                title="Delegados Natos"
                type_select="born-delegate"
                report_type="county"
              />

              <span>Relação de Delegados Natos</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Anexos"
                type_select="exportArchives"
                report_type="county"
              />

              <span>Exportar Anexos</span>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="2" value="tab-2">
          <reportGraphCounty
            v-if="conference_id"
            :conference_id="conference_id"
            :phase_id="1"
            :type_request="'INSTITUTE'"
          >
          </reportGraphCounty>

          <div class="main-report">
            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Delegados"
                type_select="delegate"
                report_type="institute"
              />
              <span>Relação de Delegados</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Propostas"
                type_select="proposal"
                report_type="institute"
              />
              <span>Relação de Propostas</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Diretrizes"
                type_select="guideline"
                report_type="institute"
              />
              <span>Relação de Diretrizes</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Facilitadores"
                type_select="facilitator"
                report_type="institute"
              />

              <span>Relação de Facilitadores</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Comissões"
                type_select="comission"
                report_type="institute"
              />

              <span>Relação de Comissões</span>
            </div>
            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Observações"
                type_select="observation"
                report_type="institute"
              />

              <span>Relação de Observações</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Anexos"
                type_select="exportArchives"
                report_type="institute"
              />

              <span>Exportar Anexos</span>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="3" value="tab-3">
          <reportGraphCounty
            v-if="conference_id"
            :conference_id="conference_id"
            :phase_id="2"
            :type_request="'REGIONAL_NUCLEUS_HEALTH'"
          >
          </reportGraphCounty>
          <div class="main-report">
            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Delegados"
                type_select="delegate"
                report_type="regionalNucleusHealth"
              />
              <span>Relação de Delegados</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Propostas"
                type_select="proposal"
                report_type="regionalNucleusHealth"
              />
              <span>Relação de Propostas</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Diretrizes"
                type_select="guideline"
                report_type="regionalNucleusHealth"
              />
              <span>Relação de Diretrizes</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Facilitadores"
                type_select="facilitator"
                report_type="regionalNucleusHealth"
              />

              <span>Relação de Facilitadores</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Comissões"
                type_select="comission"
                report_type="regionalNucleusHealth"
              />

              <span>Relação de Comissões</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Observações"
                type_select="observation"
                report_type="regionalNucleusHealth"
              />

              <span>Relação de Observações</span>
            </div>

            <div class="btn-emitir" v-if="initial_macro_phase_date != null">
              <btnEmit
                :resource="resource"
                title="Delegados Natos"
                type_select="born-delegate"
                report_type="regionalNucleusHealth"
              />

              <span>Relação de Delegados Natos</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Anexos"
                type_select="exportArchives"
                report_type="regionalNucleusHealth"
              />

              <span>Exportar Anexos</span>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="4" value="tab-4">
          <reportGraphCounty
            v-if="conference_id"
            :conference_id="conference_id"
            :phase_id="3"
            :type_request="'STATE'"
          >
          </reportGraphCounty>
          <div class="main-report">
            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Delegados"
                type_select="delegate"
                report_type="state"
              />
              <span>Relação de Delegados</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Propostas"
                type_select="proposal"
                report_type="state"
              />
              <span>Relação de Propostas</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Diretrizes"
                type_select="guideline"
                report_type="state"
              />
              <span>Relação de Diretrizes</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Facilitadores"
                type_select="facilitator"
                report_type="state"
              />

              <span>Relação de Facilitadores</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Comissões"
                type_select="comission"
                report_type="state"
              />

              <span>Relação de Comissões</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Observações"
                type_select="observation"
                report_type="state"
              />

              <span>Relação de Observações</span>
            </div>

            <div class="btn-emitir">
              <btnEmit
                :resource="resource"
                title="Anexos"
                type_select="exportArchives"
                report_type="state"
              />

              <span>Exportar Anexos</span>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axios from "@/axios";
import btnEmit from "../dialogs/dialog-emit.vue";
import reportGraphCounty from "./reportGraphs/reportGraphsCounty-comp.vue";

export default {
  data() {
    return {
      initial_macro_phase_date: null,
      disableBornDelegate: true,
      disabledCounty: false,
      disabledInstitute: false,
      disableRegionalNucleusHealth: true,
      disableState: true,
      tab: null,
      openModal: false,
      title: null,
      resource: {
        title_conference: null,
        conference_id: null,
      },
    };
  },

  async created() {
    await axios.get("/conference/current").then((response) => {
      this.conference_id = response.data.id;
      this.resource.title_conference = response.data.title;
      this.resource.conference_id = response.data.id;
      this.title = response.data.title;
      this.initial_macro_phase_date = response.data.initial_macro_phase_date;
    });
  },

  components: {
    btnEmit,
    reportGraphCounty,
  },

  methods: {
    // firstConference() {
    //   axios.get(`conference/${this.$route.query.id}`).then((res) => {
    //     this.resource.title_conference = res.data.title;
    //     this.resource.conference_id = res.data.id;
    //     this.title = res.data.title;
    //     this.initial_macro_phase_date = res.data.initial_macro_phase_date;
    //   });
    // },
  },
};
</script>

<style>
.main-report {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}

.btn-emitir {
  display: flex;
  gap: 5px;
  align-items: center;
}

.btn-emitir > span {
  margin-left: 20px;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.responsibility {
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.responsibility span span {
  font-weight: 500;
}

.responsibility p {
  margin: 0;
}

.responsibility p span {
  font-weight: 500;
}

.table-button-modal {
  display: flex;
  justify-content: start;
  padding-left: 8px;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
}

.table-button-modal > button {
  margin-right: 10px;
  padding: 0px 7px;
  color: white;
  cursor: pointer;
  background-color: rgb(104, 101, 101);
  border-radius: 50%;
}

.main-municipality {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-top-margin {
  margin-top: 120px !important;
}

.title-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.title-span span {
  width: auto;
  margin-right: 15px;
}

.title-span hr {
  width: 100%;
  height: 2px;
}

.municipality {
  width: 100%;
  margin-top: 15px;
}

.municipality span {
  font-weight: 500;
}

.table-dashboard {
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}

.report {
  margin: 20px 20px 20px;
}
</style>
