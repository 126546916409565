<template>
  <div>
    <v-btn @click.stop="openModal()" v-if="type_select != 'exportArchives'">
      EMITIR
    </v-btn>
    <v-btn @click.stop="openModal()" v-if="type_select === 'exportArchives'">
      BAIXAR
    </v-btn>

    <v-dialog v-model="dialog" width="800px">
      <v-card class="create-user-dialog rounded-lg">
        <div class="banner">
          <h3>RELATÓRIO - Relação de {{ title }}</h3>
          <span class="line"></span>
          <p>
            Os relatórios são emitidos em formato Excel, e seu conteúdo reflete
            a seleção determinada pelos campos abaixo.
          </p>
          <h4>{{ resource.title_conference }}</h4>
        </div>

        <!-- INICIcoO RADIO BUTTON RELATORIO DE ANEXO -->
        <div
          class="abrangency"
          v-if="report_type == 'county' && type_select === 'exportArchives'"
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              label="Municipal"
              color="indigo darken-3"
              value="county_id"
            ></v-radio>
          </v-radio-group>
        </div>

        <div
          class="abrangency"
          v-if="report_type == 'institute' && type_select === 'exportArchives'"
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              label="Instituição"
              color="indigo darken-3"
              value="institute_id"
            ></v-radio>
          </v-radio-group>
        </div>

        <div
          class="abrangency"
          v-if="
            report_type == 'regionalNucleusHealth' &&
            type_select === 'exportArchives'
          "
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              label="Macrorregião"
              color="indigo darken-3"
              value="regional_nucleus_health_id"
            ></v-radio>
          </v-radio-group>
        </div>
        <div
          class="abrangency"
          v-if="report_type == 'state' && type_select === 'exportArchives'"
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              label="Estado"
              color="indigo darken-3"
              value="state"
            ></v-radio>
          </v-radio-group>
        </div>
        <!--FIM RADIO BUTTON RELATORIO DE ANEXO -->

        <div
          class="abrangency"
          v-if="report_type == 'county' && type_select != 'exportArchives'"
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              v-if="
                this.$store.state.profile_id == 1 ||
                this.$store.state.profile_id == 2 ||
                this.$store.state.profile_id == 21 ||
                this.$store.state.profile_id == 41 ||                
                this.$store.state.profile_id == 8
              "
              label="Estadual"
              color="red"
              value="state"
            ></v-radio>
            <v-radio
              v-if="
                (this.$store.state.profile_id == 1 ||
                  this.$store.state.profile_id == 2 ||
                  this.$store.state.profile_id == 21 ||
                  this.$store.state.profile_id == 41 ||
                  this.$store.state.profile_id == 6 ||
                  this.$store.state.profile_id == 8) &&
                type_select != 'born-delegate'
              "
              label="Macroregional"
              color="red darken-3"
              value="regional_nucleus_health_id"
            ></v-radio>
            <v-radio
              v-if="
                (this.$store.state.profile_id == 1 ||
                  this.$store.state.profile_id == 2 ||
                  this.$store.state.profile_id == 21 ||
                  this.$store.state.profile_id == 6 ||
                  this.$store.state.profile_id == 41 ||
                  this.$store.state.profile_id == 8) &&
                type_select != 'born-delegate'
              "
              label="Região de Saúde"
              color="indigo"
              value="health_region_id"
            ></v-radio>
            <v-radio
              v-if="
                (this.$store.state.profile_id == 1 ||
                  this.$store.state.profile_id == 2 ||
                  this.$store.state.profile_id == 21 ||
                  this.$store.state.profile_id == 41 ||
                  this.$store.state.profile_id == 3 ||
                  this.$store.state.profile_id == 6 ||
                  this.$store.state.profile_id == 8) &&
                type_select != 'born-delegate'
              "
              label="Municipal"
              color="indigo darken-3"
              value="county_id"
            ></v-radio>
          </v-radio-group>
        </div>

        <div
          class="abrangency"
          v-if="report_type == 'institute' && type_select != 'exportArchives'"
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              v-if="
                this.$store.state.profile_id == 1 ||
                this.$store.state.profile_id == 2 ||
                this.$store.state.profile_id == 21 ||
                this.$store.state.profile_id == 41 ||
                this.$store.state.profile_id == 8
              "
              label="Estadual"
              color="red"
              value="state"
            ></v-radio>
            <v-radio
              v-if="
                this.$store.state.profile_id == 1 ||
                this.$store.state.profile_id == 2 ||
                this.$store.state.profile_id == 21 ||
                this.$store.state.profile_id == 41 ||
                this.$store.state.profile_id == 6 ||
                this.$store.state.profile_id == 8
              "
              label="Macroregional"
              color="red darken-3"
              value="regional_nucleus_health_id"
            ></v-radio>
            <v-radio
              v-if="
                this.$store.state.profile_id == 1 ||
                this.$store.state.profile_id == 2 ||
                this.$store.state.profile_id == 21 ||
                this.$store.state.profile_id == 41 ||
                this.$store.state.profile_id == 6 ||
                this.$store.state.profile_id == 8
              "
              label="Região de Saúde"
              color="indigo"
              value="health_region_id"
            ></v-radio>
            <v-radio
              v-if="
                this.$store.state.profile_id == 1 ||
                this.$store.state.profile_id == 2 ||
                this.$store.state.profile_id == 21 ||
                this.$store.state.profile_id == 41 ||
                this.$store.state.profile_id == 4 ||
                this.$store.state.profile_id == 6 ||
                this.$store.state.profile_id == 8
              "
              label="Municipal"
              color="indigo darken-3"
              value="county_id"
            ></v-radio>
            <v-radio
              v-if="report_type == 'institute'"
              label="Instituição"
              color="indigo darken-3"
              value="institute_id"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- Radio button fase macro -->
        <div
          class="abrangency"
          v-if="
            report_type == 'regionalNucleusHealth' &&
            type_select != 'exportArchives'
          "
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              v-if="
                this.$store.state.profile_id == 1 ||
                this.$store.state.profile_id == 2 ||
                this.$store.state.profile_id == 8
              "
              label="Estadual"
              color="red"
              value="state"
            ></v-radio>
            <v-radio
              label="Macroregional"
              color="red darken-3"
              value="regional_nucleus_health_id"
              v-if="type_select != 'born-delegate'"
            ></v-radio>
          </v-radio-group>
        </div>
        <!-- FIM Radio button fase macro -->

        <!-- Radio button fase Estadual -->
        <div
          class="abrangency"
          v-if="report_type == 'state' && type_select != 'exportArchives'"
        >
          <span>Abrangência</span>
          <v-radio-group v-model="radioControl" column>
            <v-radio
              v-if="
                this.$store.state.profile_id == 1 ||
                this.$store.state.profile_id == 2 ||
                this.$store.state.profile_id == 8
              "
              label="Estadual"
              color="red"
              value="state"
            ></v-radio>
          </v-radio-group>
        </div>

        <!-- <v-radio
          v-if="
            this.$store.state.profile_id == 1 ||
            this.$store.state.profile_id == 2 ||
            this.$store.state.profile_id == 8
          "
          label="Estadual"
          color="red"
          value="state"
        ></v-radio> -->
        <!-- FIM Radio button fase Estadual -->
        <v-select
          v-show="radioControl === 'regional_nucleus_health_id'"
          v-model="select_id"
          :items="item_macro"
          item-text="name"
          item-value="id"
          label="Selecione macro"
          outlined
          clearable
        ></v-select>
        <v-select
          v-show="radioControl === 'health_region_id'"
          :items="item_health"
          item-text="name"
          item-value="id"
          v-model="select_id"
          label="Selecione saúde"
          outlined
          clearable
        ></v-select>
        <v-select
          v-show="radioControl === 'county_id'"
          :items="item_county"
          item-text="name"
          item-value="id"
          v-model="select_id"
          label="Selecione municipal"
          outlined
          clearable
        >
          <template slot="item" slot-scope="item_county">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ item_county.item.name }} -
            {{ item_county.item.health_region.regional_nucleus_heath.name }}
          </template>
        </v-select>

        <v-select
          v-show="radioControl === 'institute_id'"
          :items="item_institute"
          item-text="name"
          item-value="id"
          v-model="select_id"
          label="Selecione Instituição"
          outlined
          clearable
        ></v-select>
        <div class="btn-emit-ex">
          <v-btn color="#22A65F" @click="submitDownload()" dark>
            EMITIR
            <span class="custom-loader">
              <v-icon light>mdi-file-excel-outline</v-icon>
            </span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/axios";
//import fileDownload from "js-file-download";
export default {
  data() {
    return {
      dialog: false,
      radioControl: "",
      select_id: null,
      item_health: [],
      item_macro: [],
      item_county: [],
      item_institute: [],
      counties_filtered: [],
      regional_nucleus_health_filtered: [],
      nameRules: [(v) => !!v || "Campo obrigatório!"],
    };
  },

  props: {
    resource: {
      type: Object,
    },
    title: null,
    type_select: null,
    report_type: null,
  },

  methods: {
    filterCountiesByRegionalNucleusHealth(
      counties_array,
      regional_nucleus_health_id
    ) {
      return counties_array.filter(
        (county) =>
          county.health_region.regional_nucleus_health_id ==
          regional_nucleus_health_id
      );
    },

    filterRegionalNucleusHealthById(
      array_regional_nucleus_health,
      regional_nucleus_health_id
    ) {
      return array_regional_nucleus_health.filter(
        (regionalNucleusHealth) =>
          regionalNucleusHealth.id == regional_nucleus_health_id
      );
    },

    filterHealthRegionByRegionalNucleusHealthId(
      health_region_array,
      regional_nucleus_health_id
    ) {
      return health_region_array.filter(
        (health_region) =>
          health_region.regional_nucleus_health_id == regional_nucleus_health_id
      );
    },

    filterCountiesByCountyId(array_county, county_id) {
      return array_county.filter((county) => county.id == county_id);
    },

    loadSelects() {
      axios.get("health-region").then((res) => {
        if (this.$store.state.regional_nucleus_health_id) {
          this.item_health = this.filterHealthRegionByRegionalNucleusHealthId(
            res.data,
            this.$store.state.regional_nucleus_health_id
          );
        } else {
          this.item_health = res.data;
        }
      });

      axios.get("regional-nucleus-health").then((res) => {
        if (this.$store.state.regional_nucleus_health_id) {
          this.item_macro = this.filterRegionalNucleusHealthById(
            res.data,
            this.$store.state.regional_nucleus_health_id
          );
        } else {
          this.item_macro = res.data;
        }
      });

      axios.get("county").then((res) => {        
        if (this.$store.state.regional_nucleus_health_id) {
          this.item_county = this.filterCountiesByRegionalNucleusHealth(
            res.data,
            this.$store.state.regional_nucleus_health_id
          );
        } else if (this.type_select == "exportArchives") {
          res.data.sort(function (countyA, countyB) {
            if (
              countyA.health_region.regional_nucleus_heath.name <
              countyB.health_region.regional_nucleus_heath.name
            ) {
              return -1;
            }
            if (
              countyA.health_region.regional_nucleus_heath.name >
              countyB.health_region.regional_nucleus_heath.name
            ) {
              return 1;
            }
            return 0;
          });

          this.item_county = res.data;
        } else if (this.$store.state.county_id) {
          this.item_county = this.filterCountiesByCountyId(
            res.data,
            this.$store.state.county_id
          );
        } else {
          this.item_county = res.data;
        }
      });

      axios.get("institute").then((res) => {
        this.item_institute = res.data;
      });
    },
    submitDownload() {      
      
      if (this.radioControl == "") {
        this.$swal({
          icon: "error",
          title: "Erro ao pesquisar relatório",
          text: "A escolha de abrangência é obrigatória",
        });
        return;
      }
      if (this.type_select == "exportArchives") {
        let obj = new Object();
        if (this.radioControl == "county_id") {
          obj.conference_id = this.$route.query.id;
          obj.county_id = this.select_id;
        }

        if (this.radioControl == "institute_id") {
          obj.conference_id = this.$route.query.id;
          obj.institute_id = this.select_id;
        }

        if (this.radioControl == "regional_nucleus_health_id") {
          obj.conference_id = this.$route.query.id;
          obj.regional_nucleus_health_id = this.select_id;
        }

        if (this.radioControl == "state") {
          obj.conference_id = this.$route.query.id;
          obj.state_id = 5;
        }

        axios
          .post(`archives/export`, obj, { responseType: "blob" })
          .then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/zip" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              this.resource.title_conference + ".zip"
            );
            document.body.appendChild(link);
            link.click();
          });

        return;
      }

      axios
        .get(
          `report/${this.type_select}?conference_id=${this.$route.query.id}&${
            this.radioControl
          }=${this.select_id == null ? true : this.select_id}&reportType=${
            this.report_type
          }`,
          { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório SCSEB ${this.title}`;
          link.click();
          URL.revokeObjectURL(link.href);
          //fileDownload(res.data, 'relatorio.xls');
        });
    },

    openModal() {
      this.loadSelects();
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.create-user-dialog {
  height: 500px;
  padding: 30px;
}

.btn-emit-ex {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-emit-ex > button {
  width: 70%;
}

.abrangency {
  margin-top: 10px;
  height: 200px;
}

.abrangency span {
  font-weight: 500;
}

.banner {
  display: flex;
  flex-direction: column;
}

.banner p {
  margin-top: 10px;
}

.line {
  height: 2px;
  width: 100%;
  background-color: #2f3038;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
</style>